<template>
    <div class="container">


        <div class="credit-time-norm-template-form mt-4">
            <h4 style="text-align: center">Шаблон нормы времени по видам учебной работы</h4>


            <div class="form-group mt-4">
                <div class="col-md-4">
                    <label for="value">Количество кредитов:</label>
                    <input id="value" type="number" class="form-control" placeholder="Количество кредитов"
                           v-model="creditTimeNormTemplateValue">
                </div>
            </div>

            <div class="form-group mt-4">
                <button @click="addRowCreditTimeNormTemplateInfo" type="button" class="btn btn-secondary"><i
                        class="fas fa-plus-circle"></i>
                    Добавить
                </button>
            </div>


            <div class="form-row">

                <div class="card mt-4">
                    <div class="card-body">


                        <div class="mt-4 border p-2"
                             v-for="(creditTimeNormTemplateInfo, index) in creditTimeNormTemplate_form.newCreditTimeNormTemplateInfos"
                             :key="index">
                            <div>
                                <!--                                <select class="form-control form-select" id="sp_education_work_id"-->
                                <!--                                        @change="changeFormationEducationProgramData(item, 'sp_education_work_id', $event)">-->
                                <!--                                    <option v-for="(educationWorkTypes, index) in creditTimeNormTemplate_form.educationWorkTypes"-->
                                <!--                                            :value="educationWorkTypes.id"-->
                                <!--                                            :key="index"-->
                                <!--                                            :selected="educationWorkTypes.id == creditTimeNormTemplateInfo.sp_education_work_id">-->
                                <!--                                        {{ educationWorkTypes.name_ru }}-->
                                <!--                                    </option>-->
                                <!--                                </select>-->
                                <p class="card-text">
                                    <strong>Вид уч. работы</strong>
                                    {{creditTimeNormTemplate_form.educationWorkTypes.length?creditTimeNormTemplate_form.educationWorkTypes.find(i=>i.id==creditTimeNormTemplateInfo.sp_education_work_id).name_ru:''}}
                                </p>

                            </div>
                            <div class="row">
                                <template v-for="(week, weekKey) of creditTimeNormTemplateInfo" :key="weekKey">
                                    <div class="col-md-4 mt-1 mb-2" v-if="weekKey.startsWith('w_')">
                                        <label :for="weekKey">Неделя {{ weekKey.substr(2) }}</label>
                                        <input :id="weekKey" type="number" class="form-control" min="1" max="3"
                                               :value="week"
                                               @change="changeWeekHour(creditTimeNormTemplateInfo, weekKey, $event)">
                                    </div>
                                </template>
                            </div>


                        </div>


                    </div>
                </div>


                <div class="text-center mt-2" v-if="creditTimeNormTemplate_form.newCreditTimeNormTemplateInfos.length">
                    <button class="btn btn-primary" @click="saveCreditTimeNorm">Сохранить</button>
                </div>

            </div>

        </div>


        <table v-for="(creditTimeNormValue,creditTimeNormKey, creditTimeNormIndex) of creditTimeNormTemplate_form.creditTimeNorm"
               :key="creditTimeNormIndex"
               class="table table-bordered table-responsive mb-2 mt-2">

            <thead>
            <tr>
                <td rowspan="2"><strong>Кол-во кред.</strong></td>
                <td rowspan="2"><strong>Вид уч. работы</strong></td>
                <td rowspan="2"><strong>Кол-во часов</strong></td>
                <td colspan="15">
                    <strong>Недели / академические часы</strong>
                </td>
            </tr>
            <tr>
                <td v-for="(week, weekKey) of weeks" :key="weekKey">
                    <strong>{{week}}</strong>
                </td>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(educationWorkType, educationWorkTypeKey) of creditTimeNormValue" :key="educationWorkTypeKey">
                <td v-if="educationWorkTypeKey==0" rowspan="4">{{creditTimeNormKey}}</td>
                <td>
                    {{creditTimeNormTemplate_form.educationWorkTypes.length?creditTimeNormTemplate_form.educationWorkTypes.find(i=>i.id==educationWorkType.sp_education_work_id).name_ru:''}}
                </td>
                <td>{{educationWorkType.sum}}</td>
                <template v-for="(week, weekKey) of educationWorkType" :key="weekKey">
                    <template v-if="weekKey.startsWith('w_')">
                        <td>{{week}}</td>
                    </template>
                </template>
            </tr>
            </tbody>

        </table>
    </div>
</template>

<script>

    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "CreditTimeNormTemplate",


        data() {
            return {
                creditTimeNormTemplateValue: null
            }
        },
        computed: {
            ...mapState('creditTimeNormTemplate', ['creditTimeNormTemplate_form']),
            weeks() {
                const weeks = []
                for (let i = 1; i <= 15; i++) {
                    weeks.push(i)
                }
                return weeks
            }
        },

        methods: {
            ...mapActions('creditTimeNormTemplate', ['GET_SP_EDUCATION_WORK_TYPE', 'POST_CREDIT_TIME_NORM', 'GET_CREDIT_TIME_NORM']),
            ...mapMutations('creditTimeNormTemplate', ['ADD_ROW_CREDIT_TIME_NORM_TEMPLATE_INFO', 'UPDATE_WEEK_HOUR']),

            saveCreditTimeNorm() {
                this.POST_CREDIT_TIME_NORM()
            },
            addRowCreditTimeNormTemplateInfo() {
                this.ADD_ROW_CREDIT_TIME_NORM_TEMPLATE_INFO(this.creditTimeNormTemplateValue);
            },
            changeWeekHour(item, property, e, val = 'value') {
                const value = e.target[val]
                this.UPDATE_WEEK_HOUR({item, property, value})
            }

        },
        mounted() {
            this.GET_SP_EDUCATION_WORK_TYPE();
            this.GET_CREDIT_TIME_NORM()
        }
    }
</script>

<style scoped>

</style>